<template>
    <div class="left-nav">
        <div class="nav-title">{{ title }}</div>
        <div class="sub-menu" v-for="item, i in menuList" :index="String(i + 1)" :key="i">
            <div class="sub-menu-title" :class="{ 'selected': selectedIndex == (i + 1) }"
                @click="canClick == false ? () => { } : menuClick(i + 1)">
                <span class="el-icon-caret-bottom" v-if="!item.open && item.children" @click="handleOpen(i + 1)"></span>
                <span class="el-icon-caret-top" v-if="item.open && item.children" @click="handleOpen(i + 1)"></span>
                <span class="el-icon-right" :class="{ 'selected-icon': selectedIndex == (i + 1) }"
                    v-if="!item.children"></span>
                <span class="sub-title">{{ item.name }}</span>
            </div>
            <div v-if="title=='服务项目'" class="children-list" :style="{ 'height': opens.indexOf(String(i + 1)) > -1 ? 'auto' : 0 }">
                <div class="children-item" @click="menuClick(`${(i + 1)}-${j + 1}`)"
                    :class="{ 'selected': selectedIndex == `${(i + 1)}-${j + 1}` }" v-for="subItem, j in item.children"
                    :key="j">
                    {{ subItem.name }}
                    {{ subItem.title }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'menuList', 'defIndex', 'canClick'],
    data() {
        return {
            opens: ['1', '2'],
            selectedIndex: 1,
        }
    },
    watch: {
        menuList() {
            if (this.defIndex != null) {
                this.selectedIndex = this.defIndex
            }
        }
    },
    created() {
        if (this.defIndex != null) {
            this.selectedIndex = this.defIndex
        }
        this.$bus.$on('changeLeftBarIndex', (val) => {
            this.menuClick(val);
        })
    },
    methods: {
        handleOpen(index) {
            this.menuList[index - 1].open = !this.menuList[index - 1].open;
            this.total = 1;
            this.opens = this.menuList.map((e, i) => {
                if (e.open === true) {
                    return String(i + 1)
                }
            })
            this.opens = this.opens.filter(e => {
                return e
            })
            this.$emit('menuClick', String(index))
        },
        menuClick(index) {
            this.selectedIndex = index;
            this.$emit('menuClick', String(index))
        },

    }
}
</script>

<style scoped lang="scss">
.left-nav {
    width: 270px;
    background: rgb(81, 138, 213);
    padding-top: 1px;

    .nav-title {
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        color: rgb(81, 138, 213);
        margin: 12px;
        background: #fff;
    }
}

.sub-menu {
    text-align: center;
    //margin-left: 25%;
    .sub-menu-title {
        height: 65px;
        line-height: 65px;
        font-size: 20px;
        color: #fff;
        cursor: pointer;
    }

    .children-list {
        transition: height 2s;
        -moz-transition: height 2s;
        /* Firefox 4 */
        -webkit-transition: height 2s;
        /* Safari 和 Chrome */
        -o-transition: height 2s;
        /* Opera */
        overflow: hidden;
    }

    .children-item {
        cursor: pointer;
        height: 65px;
        line-height: 65px;
        font-size: 16px;
        color: #fff;
        text-align: left;
        //margin-left: 10%;
        margin-left: 26%;
        overflow: hidden;
        //cursor: pointer;
        //height: 65px;
        //line-height: 65px;
        //font-size: 20px;
        //color: #fff;
    }
}

.sub-title {
    margin-right: 30px;
}

.selected {
    background: #fff !important;
    color: rgb(81, 138, 213) !important;

}

.selected-icon {
    border: 1px rgb(81, 138, 213) solid !important;
}

.el-icon-right {
    height: 18px;
    width: 18px;
    border-radius: 9px;
    line-height: 18px;
    text-align: center;
    margin-right: 12px;
    font-size: 16px;
    font-weight: bold;
    border: 1px #fff solid;
}
</style>