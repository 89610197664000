<template>
    <div class="animation-wrapper animation0" ref="animation">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: ['refs'],
        mounted() {
            window.addEventListener('scroll', this.handleScrollbox, true);
            this.handleScrollbox()
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScrollbox, true);
        },
        methods: {
            handleScrollbox() {
                this.currentScroll = window.pageYOffset; //表示当前滚动的位置 
                let windowHeight = window.innerHeight;
                let animation = this.$refs.animation;
                if (this.currentScroll + windowHeight >= animation.offsetTop) {
                    if (!/animation1/.test(animation.classList.value)) {
                        animation.classList.value = animation.classList.value + ' animation1';
                    } 
                } else if (windowHeight < animation.offsetTop) {
                    animation.classList.value = animation.classList.value.replace(' animation1', '');
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .animation-wrapper {
        // width: 100%;
    }
    .animation0 {
        transform: translateY(20px);
        opacity: 0;
    }
    .animation1 {
        transform: translateY(0) !important;
        opacity: 1 !important;
        transition: all 0.8s ease-out !important;
    }
</style>