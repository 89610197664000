<template>
    <div class="service-content">
        <left-navi :defIndex="leftNavBarIndex" @menuClick="menuClick" :menuList="menuList" :title="'科普教育'"></left-navi>
        <div class="right-content">
            <div v-if="selectItem">
                <div class="content-title">{{ menuList[Number(type) - 1].name }}</div>
                <div class="content-line"></div>
                <div class="detail-title">{{ selectItem.name }}</div>
                <div class="right-content pd80" v-html="selectItem.content">
                </div>

            </div>
            <div v-if="!selectItem">
                <div class="content-title">{{ menuList[Number(type) - 1].name }}</div>
                <div class="content-line"></div>
                <div class="list-wrapper">
                    <div class="list-item flex" v-for="(item, index) in itemList" :key="index" @click="itemClick(item)">
                        <div class="round"></div>
                        <div class="flex">
                            <div class="item-title">{{ item.name }}</div>
                            <div class="item-title">{{ item.createTime.split(' ')[0] }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { staticUrl } from '../../service/config'
import leftNavi from '../../components/leftNaviBar.vue'
export default {
    components: {
        leftNavi
    },
    data() {
        return {
            index: 1,
            bannerImageList: [],
            selectItem: null,
            staticUrl: staticUrl,
            dataItem: {
                content: ``
            },
            dataSource: {},
            type: '1',
            itemList: [],
            leftNavBarIndex: '1',
            menuList: [
                {
                    name: '计量文化',
                    open: true,
                },
                {
                    name: '测量技术',
                    open: true,

                }
            ],

        }
    },
    mounted() {
        this.getData()
    },
    created() {

    },
    beforeDestroy() {

    },
    methods: {
        getData() {
            this.$axios.get('/hzmeas/website/api/listServiceExample?type=1').then(res => {
                let result = res.data.result;
                this.itemList = result.records
            })
        },
        menuClick(index) {
            if (index === '1') {
                this.$router.push({
                    name: 'culture'
                })
            } else {
                this.$router.push({
                    name: 'technology'
                })
            }
        },
        itemClick(item) {
            this.selectItem = item;
            this.menuIndex = '1-1-1'
        },

    }
}
</script>

<style lang="scss" scoped>
.service-items {
    background: #ffffff;
}

.list-wrapper {
    height: 600px;
}

.list-item {
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    margin-bottom: 20px;
}

.round {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 20px;
    background: rgb(215, 215, 215);
}

.carousel-warpper {

    //   height: 540px;
    .carousel-content {
        float: left;
        color: #ffffff;
        margin: 110px 180px;
    }

    .carousel-img {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 540px;
    }
}

.service-content {
    margin: 0 160px;
    display: flex;
}

.right-content {
    background: rgb(248, 248, 248);
    flex: 1;
    // width: 600px;
    min-height: 1000px;
    padding: 60px 50px;

    .content-title {
        font-weight: bold;
        color: rgb(43, 107, 191);
        font-size: 22px;
    }

    .content-line {
        height: 4px;
        width: 100%;
        background: rgb(43, 107, 191);
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .detail-title {
        width: 100%;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        margin: 70px 0;
    }

    .detail-content {
        font-size: 21px;
        text-align: center;
    }

    .detail-img {
        width: 100%;
        height: 400px;
        margin-top: 80px;
        // margin: 80px;
    }
}

.pd80 {
    padding: 80px;
}

.list-item {

    .flex {
        flex: 1;
        justify-content: space-between;
    }
}

.list-item-wrapper {
    margin-bottom: 60px;

    .item-img {
        width: 500px;
        height: 280px;
        border-radius: 10px;
    }

    .item-content {
        padding: 0 0 0 40px;
    }

    .title {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .content {
        font-size: 22px;
        line-height: 35px;
        // width: 100%;
    }

    .time-more {
        justify-content: space-between;
        margin-top: 25px;

        .time {
            font-size: 20px;
        }

        .more {
            cursor: pointer;
            font-size: 20px;
            color: rgb(43, 107, 191);
        }
    }
}

/deep/ .el-carousel__container {
    height: 540px;
}
</style>