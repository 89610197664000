<template>
    <div class="service-content">
        <left-navi :menuList="menuList" @menuClick="menuClick" :defIndex="leftNavBarIndex" :title="'新闻资讯'"></left-navi>
        <div class="right-content">
            <div v-if="!selectItem">
                <div class="content-title">{{ menuIndex === '1' ? '新闻资讯' : '开课资讯' }}</div>
                <div class="content-line"></div>
                <div class="list-item-wrapper flex" v-for="item, index in itemList" :key="index">
                    <animation-wrapper>
                        <div class="flex">
                            <img :src="staticUrl + item.image" class="item-img" alt="">
                            <div class="item-content">
                                <div>
                                    <div class="title">{{ item.title }}</div>
                                    <span class="content" v-html="item.summary"></span>
                                </div>
                                <div class="time-more flex">
                                    <div class="time">{{ item.updateTime }}</div>
                                    <div class="more" @click="itemClick(item)">了解更多</div>
                                </div>
                            </div>
                        </div>
                    </animation-wrapper>
                </div>
                <div style="text-align: right">
                    <el-pagination background layout="prev, pager, next" @current-change="changePage" :total="total">
                    </el-pagination>
                </div>
            </div>
            <div v-if="selectItem">
                <div class="content-title">{{ menuIndex == '1' ? '新闻资讯' : '开课资讯'  }}</div>
                <div class="content-line"></div>
                <div class="detail-title">{{ selectItem.title }}</div>
                <div class="detail-content" v-html="selectItem.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import animationWrapper from '@/components/animationWrapper.vue';
import leftNavi from '../../components/leftNaviBar.vue'
import { staticUrl } from '../../service/config'
export default {
    components: {
        animationWrapper,
        leftNavi
    },
    data() {
        return {
            menuIndex: '1',
            selectItem: null,
            staticUrl: staticUrl,
            newsType: 0,
            leftNavBarIndex: 2,
            pageIndex: 1,
            total: 100,
            itemList: [

            ],
            menuList: [
                {
                    name: '开课资讯',
                    open: false,
                },
                {
                    name: '新闻资讯',
                    open: false,
                }
            ],
        }
    },

    beforeDestroy() { },

    mounted() {

        this.getData()
    },
    created() {
        if (this.$route.params.index) {
            this.leftNavBarIndex = this.$route.params.index.split('-')[1]
        }
        if (this.$route.params.item) {
            console.log(JSON.parse(this.$route.params.item),'ppppppppppppp');
            this.selectItem = JSON.parse(this.$route.params.item);
        }
        this.newsType = this.leftNavBarIndex;
        this.getData()
    },
    methods: {
        menuClick(index) {
            if (index === "1") {
                this.$router.push({
                    name: 'course'
                })
            } else {
                this.$router.push({
                    name: 'other'
                })
            }
        },
        itemClick(item) {
            this.selectItem = item;
        },
        changePage(page) {
            this.pageIndex = page
            this.$axios.get('/hzmeas/website/api/listContent?type=2&pageNo='+page).then(res => {
              let result = res.data.result;
              let itemList = result.records;
              this.itemList = itemList;
              this.total = result.total;
            })
        },

        getData() {
            this.$axios.get('/hzmeas/website/api/listContent?type=2').then(res => {
                let result = res.data.result;
                let itemList = result.records;
                this.itemList = itemList;
                this.total = result.total;
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.news-img-wrapper {
    height: 540px;
    width: 100%;
    overflow: hidden;
}

.item-tool {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.news-img {
    height: 540px;
    width: 100%;
}

.service-content {
    margin: 0 160px;
    // transform: translateY(-5px);
    display: flex;
}

.service-items {
    background: #ffffff;
}

.carousel-warpper {

    //   height: 540px;
    .carousel-content {
        float: left;
        color: #ffffff;
        margin: 110px 180px;
    }

    .carousel-img {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 540px;
    }
}

.service-content {
    margin: 0 160px;
    display: flex;
}

.right-content {
    background: rgb(248, 248, 248);
    flex: 1;
    // width: 600px;
    padding: 60px 50px;
    min-height: 1000px;

    .content-title {
        font-weight: bold;
        color: rgb(43, 107, 191);
        font-size: 22px;
    }

    .content-line {
        height: 4px;
        width: 100%;
        background: rgb(43, 107, 191);
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .detail-title {
        width: 100%;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        margin: 70px 0;
    }

    .detail-content {
        font-size: 21px;
        text-align: center;
    }

    .detail-img {
        width: 100%;
        height: 400px;
        margin-top: 80px;
        // margin: 80px;
    }
}

.pd80 {
    padding: 80px;
}

.list-item-wrapper {
    margin-bottom: 60px;

    .item-img {
      width: 300px;
      height: 200px;
        border-radius: 10px;
    }

    .item-content {
        flex: 1;
        padding: 0 0 0 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .title {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .content {
        height: 160px;
        width: 100%;
        font-size: 1rem;
        line-height: 35px;
        overflow: hidden;
        // width: 100%;
    }

    .time-more {
        justify-content: space-between;
        margin-top: 25px;

        .time {
            font-size: 20px;
        }

        .more {
            cursor: pointer;
            font-size: 20px;
            color: rgb(43, 107, 191);
        }
    }
}

/deep/ .el-carousel__container {
    height: 540px;
}

/deep/ .animation-wrapper {
    width: 100%;
}
</style>
