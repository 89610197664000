import home from '@/page/home';
import serviceItems from '@/page/serviceItems';
import news from '@/page/news';
import cases from '@/page/case';
import about from '@/page/about';
import contract from '@/page/contract';
import us from '@/page/about/aboutus';
import company from '@/page/about/company';
import teacher from '@/page/about/teacher';
import culture from '@/page/case/culture';
import technology from '@/page/case/technology';
import course from '@/page/news/course';
import other from '@/page/news/other';

const routes = [
    {
        path: '',
        component: home,

    },
    {
        path: '/serviceItems',
        component: serviceItems,
        name: 'serviceItems'
    },
    {
        path: '/news',
        component: news,
        name: 'news',
        children: [
            {
                path: '/news/course',
                component: course,
                name: 'course',
            },
            {
                path: '/news/other',
                component: other,
                name: 'other',
            },
        ]
    },
    {

        path: '/case',
        component: cases,
        name: 'case',
        // children: [
        //     {
        //         path: '/case/culture',
        //         component: culture,
        //         name: 'culture',
        //     },
        //     {
        //         path: '/case/technology',
        //         component: technology,
        //         name: 'technology',
        //     },
        // ]
    },
    {
        path: '/about',
        component: about,
        name: 'about',
        children: [
            {
                path: '/about/us',
                component: us,
                name: 'us',
            },
            {
                path: '/about/company',
                component: company,
                name: 'company',
            },
            {
                path: '/about/teacher',
                component: teacher,
                name: 'teacher',
            }
        ]
    },
    {
        path: '/contract',
        component: contract,
        name: 'contract',
    }
]
export default routes