
<template>
  <div class="footer">
    <div class="top flex">
      <div class="nav">
      <div class="nav-item">
        <span>服务项目</span>
        <div class="sub-content" @click="pushTo('2','1')">企业管理资询</div>
        <div class="sub-content" @click="pushTo('2','2')">测量技术培训</div>
      </div>
      <div class="nav-item">
        <span>新闻资讯</span>
        <div class="sub-content" @click="pushTo('3','1')">开课资讯</div>
        <div class="sub-content" @click="pushTo('3','2')">新闻资讯</div>
      </div>
      <div class="nav-item">
        <span>科普教育</span>
<!--        <div class="sub-content" @click="pushTo('4','1')">计量文化</div>-->
<!--        <div class="sub-content" @click="pushTo('4','2')">测量技术</div>-->
        <div class="sub-content" v-for="item in example" :key="item.value" @click="pushTo('4',`${item.value}`)">
           {{item.title}}
        </div>
      </div>
      <div class="nav-item">
        <span>关于我们</span>
        <div class="sub-content" @click="pushTo('5','1')">公司简介</div>
        <div class="sub-content" @click="pushTo('5','2')">师资队伍</div>
        <div class="sub-content" @click="pushTo('5','3')">联系我们</div>
      </div>
      <div class="nav-item">
        <span>培训查询</span>
        <div class="sub-content" @click="pushTo('6','1')">证书查询</div>
      </div>
    </div>
    <div class="contact">
      <!-- <div class="left">
        <img class="logo" src="../assets/logo.png" alt="" />
        <div class="contact-info">
          <div class="company-name-cn">苏州质普测量技术服务有限公司</div>
          <div class="company-name-us">Suzhou Zhipu Technology Co.,Ltd.</div>
         
        </div>
      </div> -->
      <div class="right">
        <div class="address"><img class="phone-img" src="../assets/footer-address.png" alt="" />江苏省苏州相城区渭塘镇爱格豪路9号1号楼3楼</div>
        <div class="company-phone-num">
            <img class="phone-img" src="../assets/footer-tel.png" alt="" />0512-65867772
          </div>
        <div class="flex">
          <img class="phone-img" src="../assets/footer-wechat.png" alt="" />
          <img class="scan-code" src="../assets/code.jpeg" alt="" />
        </div>
        
      </div>
    </div>
    </div>


    <div class="copyright">
      版权所有：苏州质普测量技术服务有限公司 苏 ICP备：18057518号
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        currentIndex: '0',
        example: '', // 科普教育
      }
    },
    created() {
      this.$axios.get('/hzmeas/website/api/queryDictItemsByCode?code=example_type').then(res => {
        let result = res.data.result;
        this.example = result;
      })
    },
    methods: {
      pushTo(index, activeIndex) {
        this.$bus.$emit('naviBarClick');
        console.log('index',index)
        // let temp =  String(activeIndex).split('-')
        // let index = temp[0];
        if (this.currentIndex === index) {
          let naviIndex = activeIndex
          this.$bus.$emit('changeLeftBarIndex', naviIndex);
          return
        }
        this.currentIndex = index;
        if (index === '2') {
        this.$router.push({
          name:'serviceItems',
          params: {index: activeIndex}
        });
      } else if (index === '1') {
        this.$router.push('/');
        // this.$router.push('/about')
      } else if (index === '3') {
          this.$router.push({
            name: 'news'
          })
        // this.$router.push({
        //   name: 'news',
        //   params: {index: activeIndex}
        // });
        //   if (activeIndex === '1'){
        //     this.$router.push({
        //       name: 'news'
        //     })
        //   } else {
        //     this.$router.push({
        //       name: 'news'
        //     })
        //   }
      } else if (index === '4') {
          this.$router.push({
            name: 'case'
          })
        // console.log('case');
        //   if (activeIndex === '1'){
        //     this.$router.push({
        //       name: 'case'
        //     })
        //   } else {
        //     this.$router.push({
        //       name: 'culture'
        //     })
        //   }
      } else if (index === '5') {
        // this.$router.push({
        //   name: 'about',
        //   params: {index: activeIndex}
        // })
          console.log('activeIndex',activeIndex,"dfds")
          if (activeIndex === '3'){
            this.$router.push({
              name: 'us'
            })
          } else if (activeIndex === '2') {
            this.$router.push({
              name: 'teacher'
            })
          }else {
            this.$router.push({
              name: 'company'
            })
          }
      } else if (index === '6') {
        this.$router.push('/contract')
      }
      }
    }
  }
</script>
<style lang="scss" scoped>
.footer {
  font-family: "Microsoft YaHei";
  height: 500px;
  padding: 0 120px;
  background: rgb(45, 45, 47);
  border-top: rgb(169, 130, 12) solid 12px;
  .top {
    justify-content: space-between;
  }
}
.nav {
  display: flex;
  justify-content: center;
  padding-top: 60px;
}
.nav-item {
  width: 220px;
  color: #ffffff;
  font-size: 25px;
  text-align: left;
  span {
    display: block;
    width: 180px;
    border-bottom: 1px rgba(231, 231, 231, 0.2) solid;
    padding-bottom: 25px;
    cursor: pointer;
  }
  .sub-content {
    margin-top: 20px;
    font-size: 20px;
    color: rgb(200, 200, 200);
    cursor: pointer;
  }
}
.contact {
  display: flex;
  justify-content: space-around;
  align-content: center;
  margin-top: 60px;
  // height: 90px;
  // padding: 30px 0;
  .left {
    display: flex;
    align-items: center;
  }
  .right {
    align-items: center;
    justify-content: space-around;
    .scan-code {
      width: 180px;
      border-radius: 5px;
    }
  }
}

.copyright {
  padding-top: 25px;
  margin-top: 30px;
  color: gray;
  font-size: 20px;
  // transform: scale(0.5);
  text-align: center;
  border-top: 1px solid rgba(231, 231, 231, 0.2);
}
.company-phone-num {
    // font-weight: bold;
    font-size: 22px;
    color: #ffffff;
    //   width: 18px;
    //   transform: translateY(2px);
    // }
    margin-bottom: 20px;
  }
  .address {
      font-size: 22px;
      margin: 20px 0;
      color: #ffffff;
    }
  .phone-img {
      // width: 100px;
      // width: ;
      height: 25px !important;
      margin-right: 20px;
      transform: translateY(5px);
    }
.logo {
  width: 90px;
  // height: 54px;
}
</style>