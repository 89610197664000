import Vue from 'vue'
import App from './App.vue'
import bus from './service/bus'
import {
  Tabs,
  TabPane,
  Carousel,
  CarouselItem,
  Menu,
  Submenu,
  MenuItemGroup,
  MenuItem,
  Pagination,
  Input,
  Button,
  Alert
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VueRouter from 'vue-router';
import routes from '@/router/routes'
import axios from 'axios'

import BaiduMap from 'vue-baidu-map'

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}


Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'YOUR_APP_KEY'
})
axios.defaults.baseURL = 'https://www.hzmeas.cn';
Vue.prototype.$axios = axios

Vue.prototype.$alert = Alert

Vue.prototype.$bus = bus

const router = new VueRouter({
  base: '/',
  mode: 'hash',
  routes: routes
});
console.log("router ===================", router)
// router.beforeEnter((to, from, next) => {
//   console.log(to);
//   this.$bus.$emit("changeIndex");
// })


Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Menu);
Vue.use(Alert);
Vue.use(Submenu);
Vue.use(MenuItemGroup);
Vue.use(MenuItem);
Vue.use(Pagination);
Vue.use(Input);
Vue.use(Button);
Vue.config.productionTip = false
Vue.use(VueRouter);



new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')
