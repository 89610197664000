<template>
  <div class="main" ref="main">
    <div class="content" ref="content">
      <div class="router-view" ref="scroll"><router-view></router-view></div>
      <portal-footer></portal-footer>
      <portal-tool></portal-tool>
    </div>
  </div>
</template>

<script>
import portalFooter from "@/components/footer.vue";
import portalHeader from "@/components/header.vue";
import portalTool from "@/components/toolBar.vue"
export default {
  name: "App",
  components: {
    portalFooter,
    portalHeader,
    portalTool
  },
  created() {
    this.$bus.$on('naviBarClick', () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    });
  }
};
</script>

<style>
@import url(./base.scss);

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

.router-view {
  /* padding-top: 100px; */
}

.main {
  /* display: flex; */
  /* justify-content: center; */
  width: 100%;
}

.content {
  /* width: 1094px; */
  /* min-width: 1094px; */
  /* padding: 5px; */
  background: rgb(247, 247, 247);
}

.router-view {}
</style>
