<template>
    <div>
        <portal-header :activeIndex="'3-1'"></portal-header>
        <div class="news">
            <div class="news-img-wrapper"> <img class="news-img" src="../assets/abstract-1779641_1920.jpg" /></div>
<!--            <router-view></router-view>-->
          <div class="service-content">
            <left-navi @menuClick="menuClick" :menuList="menuList" :defIndex="leftNavBarIndex"
                       :title="'新闻资讯'"></left-navi>
            <div class="right-content" v-if="menuIndex.length < 2">
              <div class="content-line"></div>
              <div class="list-item-wrapper flex" v-for="item, index in itemList" :key="index">
                <animation-wrapper>
                  <div class="flex" style="cursor: pointer;" @click="itemClick(item)">
                    <img :src="staticUrl + item.image" class="item-img" alt="">
                    <div class="item-content">
                      <div>
                        <div class="title">{{ item.title   }}</div>
                        <span class="content" v-html="item.summary"></span>
                      </div>
                      <div class="time-more flex">
                        <div class="time">{{ item.startTime }}</div>
                        <div class="more">了解更多</div>
                      </div>
                    </div>
                  </div>
                </animation-wrapper>
              </div>
              <div style="text-align: right">
                <div class="block">
                  <el-pagination background layout="prev, pager, next" @current-change="changePage" :total="total" :page-size="5">
                  </el-pagination>
                </div>
              </div>
            </div>
            <div class="right-content pd80" v-if="menuIndex.length > 1">
              <!-- <div class="content-title">计量文化</div> -->
              <div class="content-line"></div>
              <div class="detail-title">{{ selectItem.name }}</div>
              <div class="detail-content" v-html="selectItem.content"></div>
              <img class="detail-img" :src="staticUrl + selectItem.image" alt="">
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import leftNavi from '../components/leftNaviBar.vue'
import animationWrapper from '@/components/animationWrapper.vue';
import { staticUrl } from '../service/config'
import portalHeader from "@/components/header.vue";
export default {
    components: {
        leftNavi,
        animationWrapper,
        portalHeader
    },
    data() {
        return {
            index: 1,
            menuIndex: '1',
            selectItem: null,
            staticUrl: staticUrl,
            newsType: 0,
            leftNavBarIndex: 1,
            type:'1',
            pageIndex: 1,
            total: 1,
            itemList: [],
            menuList: [],
            bannerImageList: ['1111'],
        }
    },

    beforeDestroy() { },

    mounted() {
      this.$axios.get('/hzmeas/website/api/queryBannerListByPage?page=' + '2').then(res => {
        let result = res.data.result;
        this.bannerImageList = result;
      })
      this.$axios.get('/hzmeas/website/api/listContent').then(res => {
        console.log(res.data.result,'oooooooooo');
        let result = res.data.result;
        let tempArr = {};
        this.menuList = [];
        result.records.map(e => {
          if (!tempArr[e.type]) tempArr[e.type] = [];
          tempArr[e.type].push(e)
        })
        for (const key in tempArr) {
          if (Object.hasOwnProperty.call(tempArr, key)) {
            const element = tempArr[key];
            this.menuList.push({
              name: element[0].type_dictText,
              children: element,
              open: true,
            })
          }
        }
        this.dataSource = tempArr;
        this.itemList = this.dataSource[this.type];
      })
      this.$axios.get('/hzmeas/website/api/listContent?type='+this.menuIndex+'&pageNo=1&pageSize=5').then(res => {
        let result = res.data.result;
        this.total = result.total
      })
    },
    created() {
        if (this.$route.params.index) {
            this.leftNavBarIndex = this.$route.params.index.split('-')[1]
        }
    },
    methods: {
        // menuClick(index) {
        //     if (index === "1") {
        //         this.$router.push({
        //             name: 'course'
        //         })
        //     } else {
        //         this.$router.push({
        //             name: 'other'
        //         })
        //     }
        // },
        menuClick(index) {
          this.menuIndex = index;
          this.type = index;
          // console.log(this.dataSource,this.type)
          this.itemList = this.dataSource[this.type];
          // console.log( this.itemList)

          // debugger
          this.selectItem = {};
          if (index.length > 1) {
            let temp = index.split('-');
            let selectIndex = temp[1] - 1;
            this.selectItem = this.dataSource[temp[0]][selectIndex];
            // debugger
          }

        },
        itemClick(item) {
          this.selectItem = item;
          this.menuIndex = '1-1-1'
        },
        changePage(page) {
          // this.pageIndex = page
          // this.getData();
          this.pageIndex = page
          this.$axios.get('/hzmeas/website/api/listContent?type='+this.menuIndex+'&pageNo='+page+'&pageSize=5').then(res => {
            let result = res.data.result;
            let itemList = result.records;
            this.itemList = itemList;
            this.total = result.total;
          })
        },
        getData() {

        }
    },
}
</script>

<style lang="scss" scoped>
.news-img-wrapper {
    height: 540px;
    width: 100%;
    overflow: hidden;
}

.item-tool {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.news-img {
    height: 540px;
    width: 100%;
}

.service-content {
    margin: 0 160px;
    // transform: translateY(-5px);
    display: flex;
}

.service-items {
    background: #ffffff;
}

.carousel-warpper {

    //   height: 540px;
    .carousel-content {
        float: left;
        color: #ffffff;
        margin: 110px 180px;
    }

    .carousel-img {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 540px;
    }
}

.service-content {
    margin: 0 160px;
    display: flex;
}

.right-content {
    background: rgb(248, 248, 248);
    flex: 1;
    // width: 600px;
    padding: 60px 50px;

    .content-title {
        font-weight: bold;
        color: rgb(43, 107, 191);
        font-size: 22px;
    }

    .content-line {
        height: 4px;
        width: 100%;
        background: rgb(43, 107, 191);
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .detail-title {
        width: 100%;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        margin: 70px 0;
    }

    .detail-content {
        font-size: 21px;
        text-align: center;
    }

    .detail-img {
        width: 100%;
        height: 400px;
        margin-top: 80px;
        // margin: 80px;
    }
}

.pd80 {
    padding: 80px;
}

.list-item-wrapper {
    margin-bottom: 60px;

    .item-img {
      width: 300px;
      height: 200px;
        border-radius: 10px;
    }

    .item-content {
        flex: 1;
        padding: 0 0 0 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .title {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .content {
        height: 160px;
        width: 100%;
        font-size: 22px;
        line-height: 35px;
        overflow: hidden;
        // width: 100%;
    }

    .time-more {
        justify-content: space-between;
        margin-top: 25px;

        .time {
            font-size: 20px;
        }

        .more {
            cursor: pointer;
            font-size: 20px;
            color: rgb(43, 107, 191);
        }
    }
}

/deep/ .el-carousel__container {
    height: 540px;
}

/deep/ .animation-wrapper {
    width: 100%;
}
</style>
