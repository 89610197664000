<template>
    <div class="tool-bar">
        <div class="item-title">
            <img src="../assets/000.png" alt="">
            <div class="title-text">
                <div class="text">质普</div>
                <div class="text" >服务</div>
            </div>
        </div>
        <div class="item"  @click="push(0)">
            <img src="../assets/kkzx.png" alt="">
            <div>开课资讯</div>
        </div>
        <div class="item" @click="push(1)">
            <img src="../assets/zscx.png" alt="">
            <div>证书查询</div>
        </div>
        <div class="item" @click="push(2)">
            <img src="../assets/lxwm.png" alt="">
            <div>联系我们</div>
        </div>
        <div class="item" @click="push(3)">
            <img src="../assets/gzh.png" alt="">
            <div>公众号</div>
        </div>
    </div>
</template>
<script>
export default {
   methods: {
    push(index) {
        let name = ''
        let actIndex = ''
        switch(index) {
            case 0: 
                name = 'news';
                actIndex = '3-1'
                break;
            case 1: 
                name = 'contract';
                actIndex = '6'
                break;
            case 2: 
                name = 'us';
                actIndex = '5-3';
                break;
            case 3:
              name = 'contract';
              actIndex = '6'
              break;
            default: 
        }
        this.$bus.$emit('changeIndex', actIndex);
        this.$router.push({
            name: name,
            params: {index: actIndex}
        })
    }
   },
}
</script>
<style lang="scss" scoped>
    .tool-bar {
        position: fixed;
        top: 40%;
        right: 30px;
        z-index: 99999;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .item-title {
        height: 70px;
        // width: 100px;
        text-align: center;
        // background: url('../assets/000.png') 100% 100% no-repeat;
        background-position: -10px 10px;
        // transform: translateX(-3px);
        font-size: 18px;
        font-weight: bold;
        word-wrap: break-word;
        text-align: center;
        padding-top: 30px;
        color: rgb(203, 165, 66);
        margin-bottom: 20px;
        img {
            width: 120px;
        }
        .title-text {
            position: relative;
            top: -65px;
            
        }
    }
    .item {
        cursor: pointer;
        height: 80px;
        width: 80px;
        border-radius: 5px;
        margin-top: 10px;
        background: rgb(81, 138, 213);;
        color: #fff;
        // line-height: 100px;
        text-align: center;
        img {
            margin-top: 6px;
            height: 40px;
            width: 40px;
        }
    }
    
</style>