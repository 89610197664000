<template>
  <div class="home">
    <portal-header :activeIndex="'1-1'"></portal-header>
    <div class="carousel-warpper" ref="carouselWarpper">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item v-for="item in bannerImageList" :key="item">
          <img class="carousel-img" :src="staticUrl + item.url" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="title">
      服务项目
      <div class="line"></div>
    </div>
    <div class="service-wrapper flex">
      <div class="service-items">
        <div class="liner-line">企业管理咨询</div>
        <div class="items">
          <animation-wrapper v-for="(item, index) in dataSource1" :key="index">
            <div class="item">
              <img :src="require(`../../static/picused${index + 1}.png`)" alt="">
              <div class="item-text">{{ item.name }}</div>
            </div>
          </animation-wrapper>
          <!-- <animation-wrapper>
            <div class="item">
              <img src="../assets/picused7.png" alt="">
              预约上门
            </div>
          </animation-wrapper>
          <animation-wrapper>
            <div class="item">
              <img src="../assets/picused5.png" alt="">
              器具管理
            </div>
          </animation-wrapper>
          <animation-wrapper>
            <div class="item">
              <img src="../assets/picused6.png" alt="">
              上门取件
            </div>
          </animation-wrapper> -->
        </div>
      </div>
      <img class="service-image" src="@/assets/furniture-1840463_1920.jpg" alt="">
<!--      <img src="src/assets/furniture-1840463_1920.jpg" alt="">-->
    </div>
    <div class="service-wrapper flex mt80 flex-content-right">
      <img class="service-image" src="@/assets/pexels-pavel-danilyuk-8761540.jpg" alt="">
<!--      <img src="src/assets/pexels-pavel-danilyuk-8761540.jpg" alt="">-->
      <div class="service-items">
        <div class="liner-line" style="text-align: left;padding-left: 120px;">测量技术培训</div>
        <div class="items">
          <animation-wrapper v-for="(item, index) in dataSource2" :key="index">
            <div class="item">
              <img :src="require(`../../static/picused${index + 5}.png`)" alt="">
              <div class="item-text">{{ item.name }}</div>
            </div>
          </animation-wrapper>
          <!-- <animation-wrapper>
            <div class="item">
              <img src="../assets/picused1.png" alt="">
              预约上门
            </div>
          </animation-wrapper>
          <animation-wrapper>
            <div class="item">
              <img src="../assets/picused2.png" alt="">
              器具管理
            </div>
          </animation-wrapper>
          <animation-wrapper>
            <div class="item">
              <img src="../assets/picused4.png" alt="">
              上门取件
            </div>
          </animation-wrapper>
         -->
        </div>
      </div>
    </div>
    <animation-wrapper>
      <div class="title">
        资讯简介
        <div class="line"></div>
      </div>
    </animation-wrapper>
    <div class="news-warpper flex">
      <div class="left-wrapper">
        <div class="news-tab flex">
          <animation-wrapper>
            <div class="tab" :class="{ 'tab-select': newsTab === 0 }" @click="newsTabChange(0)">开课资讯</div>
          </animation-wrapper>
          <animation-wrapper>
            <div class="tab" :class="{ 'tab-select': newsTab === 1 }" @click="newsTabChange(1)">新闻资讯</div>
          </animation-wrapper>
        </div>
        <div class="news-content-wrapper">
          <div class="news-content" v-if="newsTab === 0">
            <div class="first-news flex">
              <div class="first-news-img">
                <animation-wrapper>
                  <img :src="staticUrl + newsList[0].image" alt="">
                </animation-wrapper>
              </div>

              <div class="first-news-content">
                <animation-wrapper>
                  <div class="first-news-title">{{ newsList[0].title }}</div>
                  <div class="first-news-time">{{ newsList[0].startTime }}</div>
                  <div class="first-news-text">{{ newsList[0].summary }}</div>
                  <div class="first-news-more" @click="showNews(newsList[0], '3-1')">查看更多</div>
                </animation-wrapper>
              </div>

            </div>
            <div class="news-list">
              <div v-for="item, i in newsList" :key="i" @click="showNews(item, '3-1')" style="cursor: pointer;">
                <animation-wrapper v-if="i != 0">
                  <div class="news-list-item flex">
                    <div class="item-text">· {{ item.title }}</div>
                    <div class="item-time">{{ item.startTime }}</div>
                  </div>
                </animation-wrapper>
              </div>

            </div>
          </div>

          <div class="news-content lesson-list " v-if="newsTab === 1">
            <div class="first-news flex">
              <div class="first-news-img">
                <animation-wrapper>
                  <img :src="staticUrl + newsList[0].image" alt="">
                </animation-wrapper>
              </div>
              <div class="first-news-content">
                <animation-wrapper>
                  <div class="first-news-title">{{ newsList[0].title }}</div>
                  <div class="first-news-time">{{ newsList[0].startTime }}</div>
                  <div class="first-news-text">{{ newsList[0].summary }}</div>
                  <div class="first-news-more" @click="showNews(newsList[0], '3-2')">查看更多</div>
                </animation-wrapper>
              </div>

            </div>
            <div class="news-list">
              <div v-for="item, i in newsList" :key="i" @click="showNews(item, '3-2')" style="cursor: pointer;">
                <animation-wrapper v-if="i != 0">
                  <div class="news-list-item flex">
                    <div class="item-text">·{{ item.title }}</div>
                    <div class="item-time">{{ item.startTime }}</div>
                  </div>
                </animation-wrapper>
              </div>

            </div>
          </div>

        </div>
      </div>
      <div class="right-wrapper">
        <div class="news-tab flex">
          <animation-wrapper>
            <div class="tab tab-select">公司简介</div>
          </animation-wrapper>
        </div>
        <div class="news-content-wrapper" v-html="introduction"></div>
        <!--          <div class="news-content-wrapper">-->
        <!--                <div class="news-content pb20 pt20">-->
        <!--                  <animation-wrapper>-->
        <!--                    <p>-->
        <!--                      苏州质普测量技术服务有限公司成立于2018年03月，是依法注册、具有计量标准核准资质、中国合格评定国家认可委员会（CNAS）认可资质的第三方专业校准机构，中国计量协会校准委员会第一批成员单位。公司主要面向社会，接受政府部门、企事业单位委托，从事商品类、环境类、工程类各项目的计量、检测、检验、认证服务及计量器具的检定校准服务。-->
        <!--                    </p>-->
        <!--                  </animation-wrapper>-->
        <!--                  <animation-wrapper>-->
        <!--                    <p>-->
        <!--                      公司拥有实验室及办公场地3000平方米，各类标准装置500余台套，其中γ谱仪校准装置等多套标准处于国内、省内先进水平。公司设有50米长基线、精密测量等多个专业检测实验室，涵盖几何量、热学、力学、电磁学、化学、声学、专用测量仪器等多个专业领域，以及生物安全柜检测、冷链物流设施验证等专项检测能力。-->
        <!--                    </p>-->
        <!--                  </animation-wrapper>-->
        <!--                  <animation-wrapper>-->
        <!--                    <img style="margin-top: 20px;" src="../assets/pexels-francesca-cinel-9729601.jpg" alt="">-->
        <!--                  </animation-wrapper>-->
        <!--                </div>-->
        <!--          </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import animationWrapper from '@/components/animationWrapper.vue';
import portalHeader from "@/components/header.vue";
import { staticUrl } from '../service/config'
export default {
  components: {
    animationWrapper,
    portalHeader
  },
  data() {
    return {
      projectIndex: 0,
      newsTab: 0,
      dataSource1: [],
      dataSource2: [],
      leftNavBarIndex:0,
      // 公司简介
      introduction: '',
      staticUrl: staticUrl,
      newsRecords: [],
      newsList: [
        // { title: '助力质量提升 “提质强企”苏州专场活动，宏准计量总经理陈如冰受聘为…', startTime: '2022-12-06' },
        // { title: '助力质量提升 “提质强企”苏州专场活动，宏准计量总经理陈如冰受聘为…', startTime: '2022-12-06' },
        // { title: '助力质量提升 “提质强企”苏州专场活动，宏准计量总经理陈如冰受聘为…', startTime: '2022-12-06' },
        // { title: '助力质量提升 “提质强企”苏州专场活动，宏准计量总经理陈如冰受聘为…', startTime: '2022-12-06' },
        // { title: '助力质量提升 “提质强企”苏州专场活动，宏准计量总经理陈如冰受聘为…', startTime: '2022-12-06' },
        // { title: '助力质量提升 “提质强企”苏州专场活动，宏准计量总经理陈如冰受聘为…', startTime: '2022-12-06' },
        // { title: '助力质量提升 “提质强企”苏州专场活动，宏准计量总经理陈如冰受聘为…', startTime: '2022-12-06' },
      ],
      bannerImageList: ["home-banner1", "home-banner2", "home-banner3"],
    };
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.handleScrollbox, true)
  },
  mounted() {
    this.getData();
  },
  methods: {
    newsTabChange(val) {
      this.newsTab = val;
      this.getData();
    },
    // pushToNews() {
    //   this.$router.push({
    //     name: 'news'
    //   })
    // },
    showNews(item, index) {
      this.leftNavBarIndex = index.split('-')[1]
      if (this.leftNavBarIndex === '1'){
        this.$router.push({
          name: 'course',
          params: { index: index, item: JSON.stringify(item) }
        })
      } else {
        this.$router.push({
          name: 'other',
          params: { index: index, item: JSON.stringify(item) }
        })
      }
      // this.$router.push({
      //   name: 'news',
      //   params: { index: index, item: JSON.stringify(item) }
      // })
      this.$bus.$emit('naviBarClick')
      this.$bus.$emit('changeIndex', index)
    },
    getData() {
      let type = Number(this.newsTab) + 1;
      this.$axios.get('/hzmeas/website/api/listContent?pageSize=6&&type=' + type).then(res => {
        let result = res.data.result;
        let records = result.records;
        this.newsList = records;
      })
      this.$axios.get('/hzmeas/website/api/queryBannerListByPage?page=' + '1').then(res => {
        let result = res.data.result;
        this.bannerImageList = result;
        // console.log(this.bannerImageList)
      })

      this.$axios.get('/hzmeas/website/api/listHomeServiceItem?type=1').then(res => {
        let result = res.data.result;
        this.dataSource1 = result;
      })

      this.$axios.get('/hzmeas/website/api/listHomeServiceItem?type=2').then(res => {
        let result = res.data.result;
        this.dataSource2 = result;
      })
      this.$axios.get('/hzmeas/website/api/queryCompanyContent').then(res => {
        let result = res.data.result;
        this.introduction = result;
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.home {

  // height: 800px;
}

.tab {
  cursor: pointer;
}

.carousel-warpper {
  height: 500px;

  .carousel-content {
    float: left;
    color: #ffffff;
    margin: 110px 180px;
  }

  .carousel-img {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 500px;
  }
}

.title {
  width: 100%;
  margin-top: 80px;
  text-align: center;
  font-size: 45px;
  font-weight: bold;

  .line {
    width: 120px;
    height: 4px;
    background-color: rgb(92, 139, 207);
    margin: 30px auto;

  }
}

.service-wrapper {

  // display: flex;
  // padding: 0 150px;
  .service-items {
    width: 50%;
    height: 400px;

    .liner-line {
      // width: 100%;
      flex: 1;
      height: 90px;
      background: linear-gradient(90deg, rgba(92, 139, 207, 1), rgba(92, 139, 207, 0.9));
      color: rgb(248, 248, 248);
      font-weight: bold;
      text-align: right;
      padding-right: 120px;
      font-size: 40px;
      line-height: 90px;
    }

    .items {
      display: flex;
      padding: 15px 50px;
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        display: flex;
        justify-content: space-between;
        padding-left: 40px;
        width: 380px;
        margin-top: 20px;
        height: 110px;
        background: rgb(233, 233, 233);
        font-size: 22px;
        // text-align: center;
        line-height: 110px;
        align-items: center;

        .item-text {
          flex: 1;
          text-align: left;
          // margin-left: 40px;
          line-height: 30px;
          padding-right: 10px;

        }

        img {
          height: 40px;
          width: 40px;
          margin-right: 20px;
        }
      }
    }
  }

  .service-image {
    width: 45%;
    height: auto;
    // width: 45%;
    // height: 400px;
  }
}

.news-warpper {
  padding: 15px 80px;
  // height: 800px;
  display: flex;
  justify-content: center;
  margin-bottom: 200px;

  .tab {
    height: 60px;
    line-height: 60px;
    width: 220px;
    color: rgb(119, 119, 119);
    font-size: 20px;
    text-align: center;
  }

  .left-wrapper {
    width: 54%;
    background: rgb(242, 242, 242);
  }

  .right-wrapper {
    margin-left: 20px;
    width: 44%;
    background: rgb(242, 242, 242);
  }

  .news-content-wrapper {
    height: 100%;

    .news-content {
      margin: 20px;
      padding: 0 20px;
      background: #ffffff;
      font-size: 20px;
      color: rgb(42, 42, 42);
      line-height: 35px;

      p {
        text-indent: 40px;
      }

      .item-text {
        text-indent: 0;
        width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      img {
        width: 100%;
        height: 330px;
      }

      p {
        padding: 0 20px;
      }

      // height: 680px;
      // height: 100%;
    }
  }
}

.first-news {
  .first-news-img {
    flex: 1;
    padding-top: 20px;
    height: 300px;
  }

  .first-news-content {
    padding: 20px;
    height: 350px;
    flex: 1;

    .first-news-title {
      font-size: 20px;
      font-weight: bold;
      color: rgb(114, 114, 114);
    }

    .first-news-time {
      font-size: 20px;
      color: rgb(143, 143, 143);
      //margin-top: 20px;
    }

    .first-news-text {
      font-size: 20px;
      margin-top: 20px;
      color: rgb(42, 42, 42);
      line-height: 35px;
      text-indent: 40px;
    }

    .first-news-more {
      text-align: right;
      color: rgb(92, 139, 207);
      font-size: 22px;
      cursor: pointer;
    }
  }
}

.news-list {
  padding: 0 20px;
  // 滚动条
  height: 340px !important;
  //overflow: auto !important;
  // 滚动条消失
  //::-webkit-scrollbar{
  //  display: none !important;
  //  //width: 0 !important;
  //  //height: 0 !important;
  //  //background-color: transparent !important;
  //}
  .news-list-item {
    justify-content: space-between;
    border-bottom: 1px solid rgb(242, 242, 242);
    height: 60px;
    line-height: 60px;
    text-align: left;
    padding-left: 20px;
    font-size: 19px;
    color: rgb(51, 51, 51);
    .item-timt {
      width: 100px;
    }
  }
}

.tab-select {
  color: #ffffff !important;
  background: rgb(92, 139, 207);
}

/deep/ .el-carousel__container {
  height: 500px;
}
</style>