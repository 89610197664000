<template>
  <div class="header">
    <div class="logo-name" @click="handleClick('1')">
      <img src="../assets/logo.png" alt="" style="height: 5rem!important;"/>
<!--      <div class="company-name">质普测量-->
<!--        <div class="company-name-en">ZhiPu Measurement</div>-->
<!--      </div>-->
    </div>
    <div class="el-tabs">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" active-text-color="#518ad5">
        <el-menu-item index="1" @click="handleClick('1')">首页</el-menu-item>
        <el-submenu index="2" @click="handleClick('2')">
          <template slot="title">服务项目</template>
          <el-menu-item v-for="item in service" :key="item.value" :index="'2-'+item.value"  @click="handleClick('2-'+`${item.value}`)">
            <div style="margin-top: 8px" class="sub-item">{{item.title}}</div>
          </el-menu-item>
<!--          <el-menu-item index="2-1" @click="handleClick('2-1')">-->
<!--            <div style="margin-top: 8px" class="sub-item">企业管理咨询</div>-->
<!--          </el-menu-item>-->
<!--          <el-menu-item index="2-2" @click="handleClick('2-2')">-->
<!--            <div style="margin-top: 8px;" class="sub-item">测量技术培训</div>-->
<!--          </el-menu-item>-->
        </el-submenu>
        <el-submenu index="3" @click="handleClick('3')">
          <template slot="title">新闻资讯</template>
          <el-menu-item v-for="item in content" :key="item.value" :index="'3-'+item.value"  @click="handleClick('3-'+`${item.value}`)">
            <div style="margin-top: 8px" class="sub-item">{{item.title}}</div>
          </el-menu-item>
<!--          <el-menu-item index="3-1" @click="handleClick('3-1')">-->
<!--            <div style="margin-top: 8px" class="sub-item">开课资讯</div>-->
<!--          </el-menu-item>-->
<!--          <el-menu-item index="3-2" @click="handleClick('3-2')">-->
<!--            <div style="margin-top: 8px;" class="sub-item">新闻资讯</div>-->
<!--          </el-menu-item>-->
        </el-submenu>
        <el-submenu index="4" @click="handleClick('4')">
          <template slot="title">科普教育</template>
          <el-menu-item v-for="item in example" :key="item.value" :index="'4-'+item.value"  @click="handleClick('4-'+`${item.value}`)">
          <div style="margin-top: 8px" class="sub-item">{{item.title}}</div>
          </el-menu-item>
<!--          <el-menu-item index="4-1" @click="handleClick('4-1')">-->
<!--            <div style="margin-top: 8px" class="sub-item">计量文化</div>-->
<!--          </el-menu-item>-->
<!--          <el-menu-item index="4-2" @click="handleClick('4-2')">-->
<!--            <div style="margin-top: 8px;" class="sub-item">测量技术</div>-->
<!--          </el-menu-item>-->
        </el-submenu>
        <el-submenu index="5" @click="handleClick('5')">
          <template slot="title">关于我们</template>
          <el-menu-item index="5-1" @click="handleClick('5-1')">
            <div style="margin-top: 8px" class="sub-item">公司简介</div>
          </el-menu-item>
          <el-menu-item index="5-2" @click="handleClick('5-2')">
            <div style="margin-top: 8px" class="sub-item">师资队伍</div>
          </el-menu-item>
          <el-menu-item index="5-3" @click="handleClick('5-3')">
            <div style="margin-top: 8px;" class="sub-item">联系我们</div>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="6" @click="handleClick('6')">培训查询</el-menu-item>
      </el-menu>
    </div>
    <div class="contact">
      <img src="../assets/nav-tel.png" alt="" />
      <span>0512-65867772</span>
    </div>
  </div>
</template>
<script>

export default {
  props: ["activeIndex"],
  data() {
    return {
      // activeIndex: '1',
      activeName: "1",
      example: '', // 科普教育下拉选
      service: '', // 服务项目下拉选
      content:'',  // 新闻资讯下拉选
    };
  },
  created() {
    this.$axios.get('/hzmeas/website/api/queryDictItemsByCode?code=example_type').then(res => {
      let result = res.data.result;
      this.example = result;
    })
    this.$axios.get('/hzmeas/website/api/queryDictItemsByCode?code=service_type').then(res => {
      let result = res.data.result;
      this.service = result;
    })
    this.$axios.get('/hzmeas/website/api/queryDictItemsByCode?code=content_type').then(res => {
      let result = res.data.result;
      this.content = result;
    })
  },
  mounted() {
      this.$bus.$on('changeIndex', (val) => {
        this.activeIndex = val;
    })
  },
  methods: {
    handleClick(activeIndex) {
      this.$bus.$emit('naviBarClick');
      let temp = String(activeIndex).split('-')
      let index = temp[0];
      let currentIndex = String(this.activeIndex).split('-')[0];
      if (currentIndex === index) {
        let naviIndex = String(activeIndex).split('-')[1];
        this.$bus.$emit('changeLeftBarIndex', naviIndex);
        return
      }
      // this.activeIndex = index;
      if (index === '2') {
        this.$router.push({
          name: 'serviceItems',
          params: { index: activeIndex }
        });
      } else if (index === '1') {
        window.location.href = 'https://www.hzmeas.cn'

      } else if (index === '3') {
        this.$router.push({
          name: 'news',
          params: { index: activeIndex }
        });
      } else if (index === '4') {
        // console.log('case');
        this.$router.push({
          name: 'case',
          params: { index: activeIndex }
        });
      } else if (index === '5') {
        this.$router.push({
          name: 'company',
          params: { index: activeIndex }
        })
      } else if (index === '6') {
        this.$router.push({
          name: 'contract',
          params: { index: activeIndex }
        })
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  background: #ffffff;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  z-index: 999;

  .logo-name {
    display: flex;
    align-items: center;
    // width: 500px;
    cursor: pointer;
  }

  .company-name {
    letter-spacing: 18px;
    font-size: 28px;
    margin-right: 50px;
    transform: translateX(-40px);
    white-space: nowrap;
  }

  .company-name-en {
    font-size: 18px;
    letter-spacing: 0;
  }

  img {
    height: 180px;
  }

  .sub-item {
    // margin-top: 20px !important;
    font-size: 20px !important;
  }

  .contact {
    display: flex;
    align-items: center;
    margin-left: 40px;

    span {
      font-weight: bold;
      color: rgb(81, 138, 213);
      letter-spacing: 3px;
      font-size: 28px;
    }

    img {
      height: 40px;
      margin-right: 18px;
    }
  }

  .el-tabs {
    margin-top: 8px;
  }

  /deep/.el-tabs__header {
    margin: 0 !important;
  }

  /deep/.el-tabs__nav-wrap::after {
    display: none;
  }

  /deep/.el-menu-item {
    font-size: 24px !important;
    font-weight: normal;
    color: black !important;
    // padding-top: 20px;
  }

  /deep/.el-submenu__title {
    font-size: 24px !important;
    font-weight: normal;
    color: black !important;
  }

  /deep/.el-tabs__nav {
    height: 80px;
  }

  /deep/.el-tabs__active-bar {
    height: 4px;
  }

  /deep/.el-menu.el-menu--horizontal {
    border: none;
  }

  /el-menu--popup/.el-menu--popup {
    .el-menu-item {
      margin-top: 80px !important;
    }
  }

  /deep/.el-menu--horizontal .el-menu .el-menu-item,
  .el-menu--horizontal .el-menu .el-submenu__title {
    font-size: 28px !important;
  }
}

</style>