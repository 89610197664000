<template>
    <div>
        <portal-header :activeIndex="'4-1'"></portal-header>
        <div class="service-items">
            <div class="carousel-warpper" ref="carouselWarpper">
                <el-carousel :interval="5000" arrow="always">
                    <el-carousel-item v-for="item in bannerImageList" :key="item.url">
                        <img class="carousel-img" :src="staticUrl + item.url" alt="" />
                    </el-carousel-item>
                </el-carousel>
            </div>
<!--            <router-view></router-view>-->
          <div class="service-content">
            <left-navi @menuClick="menuClick" :menuList="menuList" :defIndex="leftNavBarIndex"
                       :title="'科普教育'"></left-navi>
            <div class="right-content" v-if="menuIndex.length < 2">
              <div class="content-line"></div>
              <div class="list-item-wrapper flex" v-for="item, index in itemList" :key="index">
                <animation-wrapper>
                  <div class="flex">
                    <img :src="staticUrl + item.image" class="item-img" alt="">
                    <div class="item-content">
                      <div>
                        <div class="title">{{ item.name }}</div>
                        <span class="content" v-html="item.summary"></span>
                      </div>
                      <div class="time-more flex">
                        <div class="time">{{ item.updateTime }}</div>
                        <div class="more" @click="itemClick(item)">了解更多</div>
                      </div>
                    </div>
                  </div>
                </animation-wrapper>
              </div>
              <div style="text-align: right">
                <div class="block">
                  <el-pagination background layout="prev, pager, next" @current-change="changePage" :total="total">
                  </el-pagination>
                </div>
              </div>
            </div>
            <div class="right-content pd80" v-if="menuIndex.length > 1">
              <div class="content-title">计量文化</div>
              <div class="content-line"></div>
              <div class="detail-title">{{ selectItem.name }}</div>
              <div class="detail-content" v-html="selectItem.content"></div>
              <img class="detail-img" :src="staticUrl + selectItem.image" alt="">
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import leftNavi from '../components/leftNaviBar.vue'
import portalHeader from "@/components/header.vue";
import { staticUrl } from '../service/config'
export default {
    components: {
        leftNavi,
        portalHeader
    },
    data() {
        return {
            index: 1,
            bannerImageList: ['1111'],
            selectItem: null,
            staticUrl: staticUrl,
            menuIndex: '1',
            dataItem: {
                content: ``
            },
            dataSource: {},
            type: '1',
            itemList: [],
            leftNavBarIndex: '1',
            menuList: [],
            total: 1,
        }
    },
    mounted() {
      this.$axios.get(`/hzmeas/website/api/listServiceExample`).then(res => {
        let result = res.data.result;
        let tempArr = {};
        this.menuList = [];
        result.records.map(e => {
          if (!tempArr[e.type]) tempArr[e.type] = [];
          tempArr[e.type].push(e)
        })
        for (const key in tempArr) {
          if (Object.hasOwnProperty.call(tempArr, key)) {
            const element = tempArr[key];
            this.menuList.push({
              name: element[0].type_dictText,
              children: element,
              open: true,
            })
          }
        }
        this.dataSource = tempArr;
        this.itemList = this.dataSource[this.type];
      })
      this.$axios.get('/hzmeas/website/api/listServiceExample?type='+this.menuIndex+'&pageNo=1').then(res => {
        let result = res.data.result;
        this.total = result.total
      })
    },
    created() {
        if (this.$route.params.index) {
            this.leftNavBarIndex = this.$route.params.index.split('-')[1]
        }
        this.$axios.get('/hzmeas/website/api/queryBannerListByPage?page=' + '4').then(res => {
            let result = res.data.result;
            this.bannerImageList = result;
        })
    },
    beforeDestroy() {

    },
    methods: {
        // 路由跳转
        // menuClick(index) {
        //     if (index === '1') {
        //         this.$router.push({
        //             name: 'culture'
        //         })
        //     } else{
        //         this.$router.push({
        //             name: 'technology'
        //         })
        //     }
        // },
        menuClick(index) {
          this.menuIndex = index;
          this.type = index;
          console.log(this.dataSource,this.type)
          this.itemList = this.dataSource[this.type];
          console.log( this.itemList)

          // debugger
          this.selectItem = {};
          if (index.length > 1) {
            let temp = index.split('-');
            let selectIndex = temp[1] - 1;
            this.selectItem = this.dataSource[temp[0]][selectIndex];
            // debugger
          }

        },
        itemClick(item) {
          this.selectItem = item;
          this.menuIndex = '1-1-1'
        },
        changePage(page) {
          this.pageIndex = page
          this.$axios.get('/hzmeas/website/api/listServiceExample?type='+this.menuIndex+'&pageNo='+page).then(res => {
            let result = res.data.result;
            let itemList = result.records;
            this.itemList = itemList;
            this.total = result.total;
          })
        },
        getData() {

        },
    }
}
</script>

<style lang="scss" scoped>
.service-items {
    background: #ffffff;
}

.list-wrapper {
    height: 600px;
}

.list-item {
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    margin-bottom: 20px;
}

.round {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 20px;
    background: rgb(215, 215, 215);
}

.carousel-warpper {

    //   height: 540px;
    .carousel-content {
        float: left;
        color: #ffffff;
        margin: 110px 180px;
    }

    .carousel-img {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 540px;
    }
}

.service-content {
    margin: 0 160px;
    display: flex;
}

.right-content {
    background: rgb(248, 248, 248);
    flex: 1;
    // width: 600px;
    padding: 60px 50px;

    .content-title {
        font-weight: bold;
        color: rgb(43, 107, 191);
        font-size: 22px;
    }

    .content-line {
        height: 4px;
        width: 100%;
        background: rgb(43, 107, 191);
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .detail-title {
        width: 100%;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        margin: 70px 0;
    }

    .detail-content {
        font-size: 21px;
        text-align: center;
    }

    .detail-img {
        width: 100%;
        height: 400px;
        margin-top: 80px;
        // margin: 80px;
    }
}

.pd80 {
    padding: 80px;
}

.list-item {
    .flex {
        flex: 1;
        justify-content: space-between;
    }
}

.list-item-wrapper {
  margin-bottom: 60px;

  .item-img {
    //width: 400px;
    //height: 280px;
    width: 300px;
    height: 200px;
    border-radius: 10px;
  }

  .item-content {
    flex: 1;
    padding: 0 0 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .title {
    // font-size: 30px;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .content {
    font-size: 1rem;
    line-height: 35px;
    margin-left: 10px;
    // width: 100%;
  }

  .time-more {
    width: 54rem;
    justify-content: space-between;
    margin-top: 25px;

    .time {
      font-size: 20px;
    }

    .more {
      cursor: pointer;
      font-size: 20px;
      color: rgb(43, 107, 191);
    }
  }
}
//.list-item-wrapper {
//    margin-bottom: 60px;
//
//    .item-img {
//      width: 300px;
//      height: 200px;
//        border-radius: 10px;
//    }
//
//    .item-content {
//        padding: 0 0 0 40px;
//    }
//
//    .title {
//        font-size: 30px;
//        font-weight: bold;
//        margin-bottom: 20px;
//    }
//
//    .content {
//        font-size: 22px;
//        line-height: 35px;
//        // width: 100%;
//    }
//
//    .time-more {
//        justify-content: space-between;
//        margin-top: 25px;
//
//        .time {
//            font-size: 20px;
//        }
//
//        .more {
//            cursor: pointer;
//            font-size: 20px;
//            color: rgb(43, 107, 191);
//        }
//    }
//}

/deep/ .el-carousel__container {
    height: 540px;
}
</style>