<template>
    <div class="service-content">
        <left-navi :defIndex="leftNavBarIndex"   :menuList="menuList" @menuClick="menuClick" :title="'关于我们'">

        </left-navi>
        <div class="right-content">
            <div class="content-title">联系我们</div>
            <div class="content-line"></div>
            <div class="right-content" style="padding: 10px 0;">
                <img class="address-img" src="../../assets/address1.png" alt="">
                <div class="flex"
                    style="width: 100%;  box-shadow: 0 2px 10px 0 rgba(0, 0, 0,0.1); justify-content: space-between;">
                    <div class="address-msg">
                        <div>企业地址：江苏省苏州相城区渭塘镇爱格豪路9号1号楼3楼</div>
                        <div>联系方式：0512-65867772</div>
                        <div>电子邮件：chenrb@zxjczx.net</div>
                        <div>业务联系：15850108828（陈经理）</div>
                    </div>
                    <div class="idea">
                        <div class="input">
                            <el-input v-model="input" placeholder="电话"></el-input>
                        </div>
                        <div class="text">
                            <el-input type="textarea" :rows="4" placeholder="留言咨询" v-model="textarea">
                            </el-input>
                        </div>
                        <div style="margin-top: 30px">
                            <el-button type="primary" round @click="submit">提 交</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import animationWrapper from '@/components/animationWrapper.vue';
import { staticUrl } from '../../service/config'
import leftNavi from '../../components/leftNaviBar.vue'
export default {
    components: {
        animationWrapper,
        leftNavi
    },
    data() {
        return {
            index: 1,
            leftNavBarIndex: 3,
            title: '公司简介',
            teacherList: [],
            input: '',
            textarea: '',
            staticUrl: staticUrl,
            bannerImageList: ['about-us'],
            companyDetail: '',
            menuList: [
                {
                    name: '公司简介',
                    open: false,
                },
                {
                    name: '师资力量',
                    open: false,
                },
                {
                    name: '联系我们',
                    open: false,
                }
            ],
        }
    },
    mounted() {

    },
    created() {
        this.$axios.get('/hzmeas/website/api/listTeacher').then(res => {
            let result = res.data.result;
            this.teacherList = result.records;
        })
    },
    beforeDestroy() {

    },
    methods: {
        menuClick(i) {
            this.index = i;
            if (i == 1) {
                this.$router.push({
                    name: 'company'
                })
            } else if (i == 2) {
                this.$router.push({
                    name: 'teacher'
                })
            } else {
                this.$router.push({
                    name: 'us'
                })
            }
        },
        submit() {
            if (this.input.length < 11) {
                alert('请填写正确的手机号')
                return
            }
            this.$axios.post('/hzmeas/website/api/addAdvice', { mobile: this.input, content: this.textarea }).then(res => {
                alert('您的咨询信息已成功提交！')
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.input {
    margin-top: 20px;
    padding-right: 20px;

    /deep/.el-input__inner {
        border-radius: 30px;
    }
}

.text {
    margin-top: 20px;
    padding-right: 20px;

    /deep/.el-textarea__inner {
        border-radius: 20px;
    }
}

.service-items {
    background: #ffffff;
}

.item-img {
    width: 200px;
    height: 260px;
    // border-radius: 10px;
}

.item-content {
    padding: 0 0 0 60px;
    font-size: 22px;
    text-align: left;
}

.content-footer {
    margin-top: 20px;
    // font-size: 18px;
}

.content-info {
    margin-top: 20px;
    // font-size: 18px;
}

.item-content-title {
    text-align: left;
    font-size: 28px;
    font-weight: bold;
}

.detail-html {
    width: 1200px;
    overflow: hidden;
    // img {
    //     width: 500px;
    // }
}

.carousel-warpper {

    //   height: 540px;
    .carousel-content {
        float: left;
        color: #ffffff;
        margin: 110px 180px;
    }

    .carousel-img {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 540px;
    }
}

.service-content {
    margin: 0 160px;
    display: flex;
}

.right-content {
    // background: rgb(248, 248, 248);
    flex: 1;
    // width: 600px;
    padding: 60px 50px;
    min-height: 1000px;

    .content-title {
        font-weight: bold;
        color: rgb(43, 107, 191);
        font-size: 22px;
    }

    .content-line {
        height: 4px;
        width: 100%;
        background: rgb(43, 107, 191);
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .detail-title {
        width: 100%;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        margin: 70px 0;
    }

    .detail-content {
        font-size: 21px;
        text-align: center;

        p {
            text-align: left;
            text-indent: 45px;
            margin-bottom: 20px;
        }
    }

    .detail-img {
        width: 100%;
        height: 400px;
        margin-top: 80px;
        // margin: 80px;
    }

    .address-img {
        width: 100%;
        height: 400px;
    }
}

.list-item {
    margin-top: 40px;
}

.list-item-wrapper {
    margin-bottom: 60px;

    .item-img {
        width: 500px;
        height: 280px;
        border-radius: 10px;
    }

    .item-content {
        padding: 0 0 0 60px;
    }

    .title {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .content {
        font-size: 22px;
        line-height: 35px;
        // width: 100%;
    }

    .time-more {
        justify-content: space-between;
        margin-top: 25px;

        .time {
            font-size: 20px;
        }

        .more {
            cursor: pointer;
            font-size: 20px;
            color: rgb(43, 107, 191);
        }
    }
}

.address-msg {
    padding: 30px;
    padding-left: 30px;
    font-size: 22px;
    line-height: 60px;

}

.idea {
    padding: 30px;
    text-align: right;
}

/deep/ .el-carousel__container {
    height: 540px;
}
</style>