<template>
    <div>
        <portal-header :activeIndex="'6-1'"></portal-header>
        <div class="contract">
            <img class="case-img" src="../assets/pexels-pixabay-162500.png"></img>
            <div class="flex content">
                <img class="left-img" src="../assets/about-lert.jpg" alt="">
                <div class="right-content">
                    <div class="content-title">培训证书查询</div>
                    <div class="line"></div>
                    <img class="scan-img" src="../assets/code.jpeg" alt="">
                    <div class="reminder">
                        扫一扫二维码关注公司公众号，在线查询培训证书详细信息。
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import portalHeader from "@/components/header.vue";
export default {
    components: {
        portalHeader
    }
}
</script>
<style lang="scss" scoped>
.contract {
    .content {
        padding: 30px 80px;
    }
}

.case-img {
    height: 540px;
    width: 100%;
}

.left-img {
    width: 600px;
    height: 800px;
    filter: brightness(147%) contrast(91.5%) hue-rotate(196.2deg) saturate(38%) invert(72%)
}

.right-content {
    flex: 1;
    background: rgb(242, 242, 242);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .content-title {
        font-size: 42px;
        font-weight: bold;
    }

    .line {
        width: 100px;
        height: 4px;
        background: rgb(96, 139, 207);
        margin: 20px 0 60px 0;
    }

    .scan-img {
        width: 250px;
        margin-bottom: 50px;
    }

    .reminder {
        font-size: 18px;
        line-height: 30px;
        color: rgb(153, 153, 153);
        width: 350px;
        text-align: center;
    }
}
</style>